<template>
  <v-menu
    v-model="input.period.toggle"
    :close-on-content-click="false"
    transition="scroll-y-transition"
    origin="top right"
    min-width="25%"
    max-width="320px"
    offset-y
    left
    fixed
    class="selection-control"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        :text="breakpoint('xs') ? true : false"
        :icon="breakpoint('xs') ? false : true"
        :disabled="input.period.readonly"
        small
        height="40"
        color="grey darken-2"
        class="period d-block text-right text-truncate mr-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon 
          size="18"
          :class="{ 'mr-3': breakpoint('xs') }"
        >
          {{ icons.filter }}
        </v-icon>
        {{ breakpoint('xs') ? periodDisplay : '' }} 
      </v-btn>
    </template>
    <v-card>
      <v-date-picker
        v-model="input.period.value"
        :first-day-of-week="0"
        :min="input.period.min"
        :max="input.period.max"
        no-title
        range
        full-width
        flat
        scrollable
        class="pt-2 elevation-0"
        @change="onRangeUpdate"
      />
      <v-divider class="mt-0" />

      <v-card-actions class="pr-0">
        <v-select
          v-model="input.period.select"
          :items="translate(input.period.items)"
          :loading="input.period.loading"
          :readonly="input.period.readonly"
          :label="$t(input.period.label)"
          :placeholder="$t(input.period.placeholder)"
          dense
          solo
          flat
          hide-details
          class="period pr-0"
          @change="onRangeSelectUpdate"
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<style>

  .filters .scope .v-select__slot {
    font-weight: 500;
  }

  .filters .v-input {
    /* font-size: .875rem; */
  }
  .filters .v-label {
    font-size: inherit;
  }

  .filters .v-input__slot {
    background: transparent !important;
    padding: 0 !important;
  }

  .v-application--is-ltr .v-text-field .v-input__prepend-inner {
    padding-right: 8px;
  }

  .filters .loading {
    opacity: .25;
  }
  
</style>

<script>
  // Icons
  import { mdiCalendarRange, mdiMenuDown } from '@mdi/js';
  import services from '@/services';

  export default {
    props: {
      period: {
        type: Array,
        default: () => null
      },
      min: {
        type: String,
        default: null
      },
      max: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        filter: mdiCalendarRange,
        menu: mdiMenuDown,
      },
      input: {
        period: {
          value: [],
          toggle: false,
          select: 7,
          min: null,
          max: null,
          items: [
            {
              text: 'dashboard.period.total_duration',
              value: 0,
            },
            {
              text: 'dashboard.period.last_7_days',
              value: 7,
            },
            {
              text: 'dashboard.period.last_14_days',
              value: 15,
            },
            {
              text: 'dashboard.period.last_30_days',
              value: 30,
            },
            {
              text: 'dashboard.period.last_90_days',
              value: 90,
            },
          ],
          loading: false,
          readonly: false,
          label: 'dashboard.period.label',
          placeholder: 'general.personalized',
        },
      }
    }),

    watch: {
      period: {
        immediate: true,
        deep: true,
        handler (period) {
          console.log('period', period);
          if (period!=null) {
            period[1] = this.$moment().isBefore(period[1]) ? this.$moment().format('YYYY-MM-DD') : period[1];
            if (period[1]==this.$moment().format('YYYY-MM-DD')) {
              const range = this.$moment(period[1]).diff(period[0], 'd');
              const select = _.find(this.input.period.items, ['value', range]);
              this.input.period.select = _.isNil(select) ? null : select;
            }else{
              this.input.period.select = null;
            }

            this.input.period.view = period[1];

            this.input.period.value = period;
            this.input.period.readonly = period==null;
          }
        }
      },
      min: {
        immediate: true,
        handler (v) {
          this.input.period.min = v;
        }
      },
      max: {
        immediate: true,
        handler (v) {
          this.input.period.max = v;
        }
      }
    },

    computed: {
      periodDisplay () {
        let text;
        if (this.input.period.value!=null) {
          let start = this.$moment(this.input.period.value[0]);
          let end = this.$moment(this.input.period.value[1]);
          // if (start.month()!=end.month()) {
            start = start.format('DD/MMM');
          // }else{
          //   start = start.format('DD');
          // }
          end = end.format('DD/MMM');
          text = start + ' – ' + end;
        }else {
          text = this.input.period.label;
        }
        return text;
      }
    },
    
    methods: {
      ...services,

      onRangeUpdate () {
        this.input.period.toggle = false;
        if (this.input.period.value.length>1&&this.$moment(this.input.period.value[1]).isBefore(this.input.period.value[0])) {
          this.input.period.value = this.input.period.value.reverse();
        }
        this.emitFilterUpdate();
      },
      onRangeSelectUpdate () {
        this.input.period.toggle = false;
        const range = this.input.period.select;
        if (range==0) {
          this.input.period.value = [
            this.min,
            this.max,
          ];
        }else{
          this.input.period.value = [
            this.$moment().subtract(range, 'days').format('YYYY-MM-DD'),
            this.$moment().format('YYYY-MM-DD')
          ];
        }
        console.log(this.input.period.value);
        this.emitFilterUpdate();
      },
      emitFilterUpdate () {
        this.$emit('updated', this.input.period.value);
      }
    },

    mounted () {
      
    }
  }
</script>